<template>
    <iframe class="h-100 w-100" :src="url"/>
</template>
<script setup lang="ts">
    import {computed} from "vue";

    interface Props {
        fileName: string,
        primKey: string,
        viewName: string,
        id: number
    };

    const encodeObject = (pValue: any) => {
        const jsonString = JSON.stringify(pValue);
        const encodedString = btoa(encodeURIComponent(jsonString));
        return encodedString;
    }

    const props = defineProps<Props>();
    const url = computed(() => { return `/nt/foxit9-viewer?params=${encodeObject(props)}`})
    //const url = ref(`/foxit9?param=${Url.decodObject(props)}`); 
</script>